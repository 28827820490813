<template>
    <el-dropdown trigger="click" class="mr-2" placement="bottom">
        <el-button type="primary" :disabled="!userCan('update invoices')" :loading="$wait.is('updating')">
            {{ $t('economy.change_status') }}<i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="!canConfirm" @click.native="batchChangeStatus('confirmed')">
                {{ $t('economy.mark_as_closed') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!canMarkAsSent" @click.native="batchChangeStatus('sent')">
                {{ $t('economy.mark_as_sent') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!canMarkAsPaid" @click.native="batchChangeStatus('paid')">
                {{ $t('economy.mask_as_paid') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!canGenerateRut" @click.native="batchChangeStatus('rut_sent')">
                {{ $t('economy.mark_as_rut_sent') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!canMarkAsCompleted" @click.native="batchChangeStatus('completed')">
                {{ $t('economy.mark_as_completed') }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import includes from 'lodash/includes';
import map from 'lodash/map';

export default {
    computed: {
        selectedStatus() {
            return this.$store.state.economy.selectedStatus;
        },
        selectedInvoices() {
            return this.$store.state.economy.selectedInvoices;
        },
        canConfirm() {
            return this.selectedStatus === 'draft' && this.selectedInvoices.length > 0;
        },
        canMarkAsSent() {
            return this.selectedStatus === 'confirmed' && this.selectedInvoices.length > 0;
        },
        canMarkAsPaid() {
            return includes(['sent', 'confirmed', 'unpaid', 'reminder_invoice'], this.selectedStatus) && this.selectedInvoices.length > 0;
        },
        canGenerateRut() {
            return this.selectedStatus === 'paid' && this.selectedInvoices.length > 0;
        },
        canMarkAsCompleted() {
            return includes(['rut_sent', 'paid', 'confirmed'], this.selectedStatus) && this.selectedInvoices.length > 0;
        },
    },

    methods: {
        async batchChangeStatus(status) {
            this.$wait.start('updating');

            const invoices = map(this.selectedInvoices, 'uuid');

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/status_to_${status}`, {
                invoices,
            });

            this.$notify.success({ title: 'Success' });
            this.$wait.end('updating');
            this.$emit('change');
        },
    },
};
</script>
